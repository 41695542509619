<template>
	<div class="flex flex-wrap">
		<slot></slot>
	</div>
</template>

<script>
export default {
	mounted: function () {
		let element = document.body;
		if (this.$root.isInsideIframe) {
			element = document.getElementsByTagName('iframe')[0].contentDocument
				.body;
		}
		element.style.backgroundImage = `url("${require('@/assets/images/menu.png')}")`;
	}
};
</script>

<style></style>
