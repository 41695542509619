<template>
	<div>
		<t-modal v-model="isCartOpen">
			<cart-detail
				:commands="commands"
				:total="total"
				@validated="goToPayment"
			/>
		</t-modal>
		<div class="osahan-home-page">
			<div class="border-bottom p-3"></div>
			<!-- body -->
			<div class="osahan-body">
				<!-- categories -->

				<!-- Promos -->
				<div class="py-3 bg-white osahan-promos shadow-sm">
					<div class="d-flex align-items-center px-3 mb-2">
						<h6 class="m-0">{{ restaurant.name }}</h6>
					</div>
					<component
						:is="component"
						:autoplayHoverPause="true"
						:autoplay="true"
						:loop="true"
						:center="true"
						:stagePadding="20"
						:nav="false"
						:dots="false"
						:items="1"
					>
						<div
							v-for="(image, key) in restaurant.images
								? JSON.parse(restaurant.images)
								: []"
							:key="key"
							:class="[
								'osahan-slider-item p-2',
								$root.isInsideIframe ? 'w-1/2' : ''
							]"
						>
							<div
								class="bg-cover h-40 rounded"
								:style="
									`background-image: url(${$constants.STORAGE_PATH}${image})`
								"
							/>
						</div>
					</component>
				</div>
				<p class="m-3 float-right">
					<a
						@click="openCart"
						class="text-decoration-none relative p-1 rounded shadow-sm align-items-center"
						style="background:#5c5050; font-size: 22px;"
					>
						<i
							class="text-white icofont-cart"
							style="background:#5c5050;"
						/>
						<span
							class="badge badge-green bg-success text-white p-1 ml-1 small absolute top-0 right-0 -mr-2 -mt-2"
						>
							{{ commands.length }}
						</span>
					</a>
				</p>
				<div v-if="$route.query.isReservingTable">
					<div class="border-bottom px-2 py-4">
						<div class="title d-flex align-items-center">
							<span class="font-weight-bold ml-3 h6 mb-0 flex-1">
								Book a table
							</span>
						</div>
					</div>
					<restaurant-table
						:restaurantId="$route.params.restaurantId"
						:restaurantName="restaurant.name"
						:withTableSelector="true"
						v-model="additionalForm"
					/>
					<div class="px-4 mt-4">
						<button
							@click="validate"
							type="button"
							class="btn btn-success btn-block py-3"
						>
							VALIDATE
						</button>
					</div>
				</div>
				<div v-else>
					<div class="border-bottom px-2 py-4">
						<div class="title d-flex align-items-center">
							<span class="font-weight-bold ml-3 h6 mb-0 flex-1">
								Menu
							</span>
						</div>
					</div>
					<restaurant-menu
						:restaurantId="$route.params.restaurantId"
					/>
				</div>
				<br />
				<br />
			</div>
		</div>
	</div>
</template>

<script>
import Holder from '@/layouts/Holder';
import carousel from 'vue-owl-carousel';
import CartDetail from './component/CartDetail';

import RestaurantTable from './component/RestaurantTable';
import RestaurantMenu from './component/RestaurantMenu';

import { ApiGetRestaurant } from '@/tools/apiService';
import { stripHtml, textTruncate } from '@/tools/helpers';
import { mapGetters, mapActions } from 'vuex';

export default {
	data: () => ({
		isCartOpen: false,
		component: Holder,
		additionalForm: {},
		restaurant: {}
	}),
	components: {
		CartDetail,
		RestaurantTable,
		RestaurantMenu
	},
	mounted: function() {
		this.getRestaurant();
		this.refreshCart();
	},
	computed: {
		...mapGetters({
			commands: 'cart/commands',
			total: 'cart/total'
		})
	},
	methods: {
		stripHtml,
		textTruncate,
		...mapActions({
			saveAdditionalInfo: 'cart/saveAdditionalInfo',
			refreshCart: 'cart/refreshCart'
		}),
		goToPayment: function() {
			this.$router.push({
				name: 'payment',
				params: { restaurantId: this.$route.params.restaurantId }
			});
		},
		validate: function() {
			this.refreshCart().then(() => {
				this.saveAdditionalInfo(this.additionalForm);
				this.goToPayment();
			});
		},
		openCart: function() {
			this.isCartOpen = true;
		},
		getRestaurant: function() {
			ApiGetRestaurant(this.$route.params.restaurantId).then(response => {
				this.restaurant = response.data.restaurant;
				this.component = this.$root.isInsideIframe ? Holder : carousel;
			});
		}
	}
};
</script>

<style>
.icons-intro {
	color: #c20016;
	font-size: 63px;
}

.borsearch-intro {
	border: 1px solid #9e9e9e;
}
</style>
